
.footer {
  margin-bottom: 3em;
  color: #575757;

  h3 {
    margin-bottom: 0.4em;
    font-size: 1em;
    font-weight: 500;

    @include breakpoint(medium down) {
      border-top: 2px solid #d9d9d9;
      padding-top: 1.2em;
    }
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: #575757;
    text-decoration: none;
    background-color: transparent;
  }

  .vcard {
    .region {
      display: none;
    }
    .country-name {
      display: none;
    }
  }

  .invis {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: #0a0a0a;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    overflow: hidden;
  }

  .footer-content {
    overflow: hidden;
    background-color: #f5f5f5;

    > div {
      margin: 2em auto;
    }
  }

  .wrapper {
    width: 94%;
    max-width: 1180px;
    margin: 0 auto;
    transition: width .5s ease;

    @include breakpoint(large up) {
      width: 96%;
    }
  }

  .row {
    clear: both;
    margin-top: 1.4em;
    margin-bottom: 1.4em;

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  [class*="col-"] {
    &:first-child {
      margin-left: 0;
    }

    position: relative;
    float: left;
    margin-left: 1.694915254237%;
    min-height: 1px;
  }

  .col-4 {
    width: 23.728813559322%;

    @include breakpoint(medium down) {
      width: 49.152542372881%;
      margin-bottom: 1.4em;

      &:nth-child(2n+1) {
        clear: left;
        margin-left: 0;
      }
    }

    @include breakpoint(small down) {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }

  .none {
    display: none;
  }

  .footer-links {
    @include flex();

    @include breakpoint(small down) {
      flex-wrap: wrap;
    }

    ul {
      margin-bottom: 0;
    }

    .reset {
      list-style: none outside none;
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      padding: 0;

      li {
        list-style: none outside none;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        padding: 0;
      }
    }

    li {
      display: inline-block;
      margin: 0 0 0 1.5em;

      a {
        margin-right: 0.4em;
        margin-left: 0.2em;
        color: #575757;
        text-decoration: none;
        background-color: transparent;
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      &::after {
        content: "|";
        box-sizing: border-box;
      }
    }

    >div {
      @include flex();
      @include flex-direction(column);
      @include flex-align(center);

      @include breakpoint(large down) {
        flex: 1 1 auto;
        width: auto;
      }

      @include breakpoint(small down) {
        display: block;
        float: none;
        width: 50%;
        margin: 0 0 0.5em 0;
      }
    }

    .footer-links-branding {

      .logo {
        width: 120px;
        margin-bottom: 22px;

        img {
          display: block;
          width: 100%;
        }
      }

      @include breakpoint(small down) {
        flex: 1;
        order: 1;
        width: 50%;
      }
    }

    .footer-links-important {
      font-size: .86666666666667em;

      @include breakpoint(small down) {
        order: 3;
        width: 100%;
      }
    }

    .footer-links-diclaimer {
      font-size: .86666666666667em;

      @include breakpoint(small down) {
        order: 4;
        width: 100%;
      }
    }

    .footer-links-sm {
      font-size: 1.46666666666667em;

      li {
        &::after {
          content: none;
        }
      }

      @include breakpoint(small down) {
        order: 2;
        @include flex();
        @include flex-direction(row);
        @include flex-align(right);
        flex: 1;
        width: 50%;

        ul {
          @include flex();

          li {
            @include flex-align-self(bottom);
          }
        }
      }
    }
  }
}

.fullwidth {
  clear: both;
}
