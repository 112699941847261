$light-grey: #D3D3D3 !default;

.header {

    width: 100%;
    position: relative;
    border-bottom: 1px solid $light-grey;
    padding: 20px 40px 70px 40px;

    @include breakpoint(small down) {
        padding: 10px 20px 40px 20px;

        box-shadow: inset 0 -12px 0 #4ab1eb,
                    inset 0px -15px 0px #00a4e8,
                    inset 0px -18px 0px #72a536,
                    inset 0px -23px 0px #adc427,
                    inset 0px -27px 0px #ffec00;
    }

    background-repeat: repeat-x;
    box-shadow: inset 0 -30px 0 #4ab1eb,
                inset 0px -37px 0px #00a4e8,
                inset 0px -44px 0px #72a536,
                inset 0px -56px 0px #adc427,
                inset 0px -63px 0px #ffec00;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
    width: 100%;
    max-width: rem-calc(200);

    @include breakpoint(small down) {
        max-width: rem-calc(150);
    }
}