.progress-container {
  padding-top: 50px;

  .progress {
    border-radius: 20px;
    height: 10px;

    .progress-meter {
      background-color: $grey;
      border-radius: 20px;
    }
  }

  .backLink, .step {
    text-decoration: none !important;
    font-size: rem-calc(12);
    font-weight: 500;
    color: $black;
  }

  .step {
    margin: auto 0 auto auto;
  }

  .progressInfo {
    display: flex;
    flex-direction: row;
    height: rem-calc(28);
  }
}