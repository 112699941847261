$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-font: 'Asap', Arial, serif !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-icon-primary-color: rgba(255, 255, 255, 1) !default;
$input-icon-primary-bg-color: rgba(82,162,255,255) !default;
$input-icon-primary-bg-hover-color: #52a2ff !default;
$input-placeholder-color: rgb(196, 196, 196) !default;

$autosuggest-item-height: 59px !default;

.advanced-select-wrapper {
  display: block;
  position: relative;
  margin: 0 0 1rem;
  cursor: pointer;

  input[type='text'] {
    margin-bottom: 0;
  }

  .react-select__clear-indicator {
    padding: 8px 12px;
    margin-right: 12px;

    svg {
      color: $input-color;
    }
  }

  .react-select__dropdown-indicator {
    display: none;
  }

  .react-select__control--is-focused {
    .react-select__placeholder {
      display: none;
    }
  }

  .react-select__placeholder {
    color: $input-color;
  }

  .react-select__control {
    position: relative;

    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;

    &:hover {
      border: 1px solid #c4c4c4;
    }
  }

  .react-autosuggest__suggestions-container {
    line-height: 1.75rem;
    font-size: 1.125rem;
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    letter-spacing: .02em;
    word-spacing: .02em;
    font-weight: 400;
    color: #000;
    border-radius: 0;
    background-color: #fff;
    text-overflow: ellipsis;
    padding: 0;

    &.react-autosuggest__suggestions-container--open {
      border: 1px solid $input-border-color;
      border-top: none;
      max-height: rem-calc(350);
      overflow-y: auto;
    }
  }

  .react-select__input input {
    line-height: 1rem;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-autosuggest__suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .react-select__option {
    border-top: 1px solid $input-border-color;
    line-height: $autosuggest-item-height;
    height: $autosuggest-item-height;
    padding: 0 19px;
    cursor: pointer;
    background: none;

    .highlight {
      font-weight: bold;
    }
  }

  .react-select__placeholder {
    color: $input-placeholder-color;
  }

  .react-select__option:first-child {
    border-top: none;
    height: $autosuggest-item-height - 1px;
  }

  .react-autosuggest__suggestion--highlighted {
    color: #52a2ff;
    background: none;
  }
}