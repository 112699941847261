.icon-upload {
  font-size: rem-calc(30);
  opacity: 0.7;
}

.icon-upload, label[for=filesToUpload], label[for=healthProblemsFile], .drag-here {
  color: $grey;
}

.delete {
  font-size: rem-calc(18);
  float: right;
  color: $grey;
  margin-top: 5px;
}

label[for=filesToUpload], label[for=healthProblemsFile] {
  cursor: pointer;
  font-size: rem-calc(16);
}

.upload-container {
  padding: 10%;
  width: 100%;
  border: 1px solid $grey;
  text-align: center;
  margin-bottom: 25px;
}

.upload-formats {
  display: block;
}

.preview-actions {
  font-size: rem-calc(14);
  line-height: rem-calc(30);
}

.title {
  padding: 5px;
  color: $grey;
}

.drag-active {
  opacity: 0.5;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#drag-health-file-element {
  padding: 40px;
  border: 1px dashed $grey;
  margin-top: 30px;
  width: 100%;

  .drag-here {
    margin-bottom: 20px;
  }
}
