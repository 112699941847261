.app-container {
    .grid-x {
        @include breakpoint(small down) {
            display: block;
        } 
    }

    .grid-container {
        @include breakpoint(large) {
            padding-left: rem-calc(135);
            padding-right: rem-calc(135);
        }
    }

    &.wrapper {
        @include padding(0, 0, 0, 0);
    }

    .grid-y {
      .content-container {
          @include breakpoint(small) {
              min-height: rem-calc(602);
          }

            @include breakpoint(medium) {
              min-height: rem-calc(703);
          }

            @include breakpoint(large) {
              min-height: rem-calc(687);
          }
      }

      .small-padding-top_bottom {
          @include breakpoint(small) {
            @include padding(2.47, 0, 3.75, 0);
          }
        }

      .medium-padding-top_bottom {
          @include breakpoint(medium) {
              @include padding(3.56, 0, 5.1, 0);
          }
      }

      .large-padding-top_bottom {
          @include breakpoint(large) {
              @include padding(5.81, 0, 6.25, 0);
          }
      }
  }
}

.margin-bottom-2 {
  @include margin-direction('bottom', 2.1875)
}

.margin-bottom-1 {
  @include margin-direction('bottom', 1.375)
}

