// Colors
$white: rgb(255, 255, 255); //#FFF
$black: rgb(0, 0, 0);       //#000
$blue: rgba(82,162,255,255); //#52a2ff
$light-grey: rgba(240,240,240,255); //#f0f0f0
$grey: rgba(108,108,108,255); //#6c6c6c
$silver: rgb(159, 159, 159); //#9F9F9F
$ebony-clay: rgb(34, 41, 58); //#22293A
$dove-gray: rgb(112, 112, 112); // #707070
$light-green: rgb(204, 224, 171); //#CCE0AB
$green: rgb(121, 152, 71); //#799847
$dark-green: rgb(97, 126, 51); //#617E33
$oslo-grey: rgb(138, 142, 150); //#8A8E96