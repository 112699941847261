.resumeFormContainer {
  .overview-title {
    padding: 70px 0 70px 0;
  }

  .overview-text {
    margin-top: 40px;
  }

  .exam-list {
    width: 90%;
    margin-left: 35px;

    ul {
      margin-left: 0 !important;
    }

    ul li {
      padding-bottom: 10px;
      list-style:none;
      text-indent: -35px;

      svg {
        margin-right: 10px;
        height: auto;
        width: 25px;
      }
    }

    li {
      margin-bottom: 20px;
    }
  }

  .exam-infos  {
    .box {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .exam-info-box {
      height: 100%;
      background-color: $light-grey;
      width: 97%;
      padding: 40px;
      font-size: 19px;

      a {
        color: $grey;
      }

      h3 {
        font-size: rem-calc(16);
        font-weight: bold;
        margin-bottom: 20px;
      }
    }

    @include breakpoint(small down) {
      display: block !important;

      .box {
        justify-content: flex-start;
        margin-top: 35px;
      }

      .exam-info-box {
        width: 100%;
      }
    }
  }

  .resumeForm {
    line-height: 2;

    h2 {
      font-weight: 700;
      font-size: inherit;
      padding-bottom: 20px;
    }

    p {
      color: $black;
    }

    label, small {
      color: $grey;
    }

    section {
      padding-bottom: 40px;
    }

    .agb {
      label {
        font-size: rem-calc(16);
      }
    }

    @include breakpoint(medium up) {
      .second-person-info {
        padding-left: rem-calc(35);
      }
    }
  }
}