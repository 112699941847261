.login-form {
  background-color: $light-grey;
  padding: 50px;

  input, input:focus, input:hover {
    background-color: $light-grey;
  }

  .login {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @include breakpoint(medium down) {
      flex-direction: column;
    }
  }

  .no-login {
    margin-bottom: rem-calc(70);
  }
}