.form-guide {
  .info-toggle {
    cursor: pointer;
    margin-top: rem-calc(50);
  }

  .guide {
    p {
      color: $grey !important;
    }
  }

  @include breakpoint(medium up) {
    .guide {
      padding-left: rem-calc(35);
    }
  }

  @include breakpoint(small down) {
    display: flex !important;

    .guide {
      display: none;
    }

    .field {
      padding-right: rem-calc(20);
      max-width: 95%;
    }
  }

  @include breakpoint(medium up) {
    .info-toggle {
      display: none;
    }
  }

  .guide-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: rem-calc(40);
    z-index: 10;

    .modal-content {
      padding: 65px 0 65px 0;
    }

    .close-modal {
      cursor: pointer;
      float: right;
      width: 25px;
      height: 25px;
    }
  }

  @include breakpoint(medium up) {
    .guide-modal {
      display: none;
    }
  }
}

/* guide for non directus related information */

.manual-form-guide {
  @include breakpoint(small down) {
    display: flex !important;
    flex-flow: column-reverse;

    .cell {
      min-height: auto;
    }
  }

  @include breakpoint(medium up) {
    .manual-guide {
      padding-left: rem-calc(35);
    }
  }
}