@import "~foundation-sites/scss/util/unit";
@import "~foundation-sites/scss/util/breakpoint";
@import "~foundation-sites/scss/util/flex";

.menu {
  padding: rem-calc(20);
  display: flex;
  justify-content: space-between;

  #modal-backdrop {
    height: 100vh;
    width: 100%;
    background-color: $grey;
    position: fixed;
    right: 0;
    top: 0;
    opacity: 0.5;
    z-index: 1;
  }

  .info-modal {
    position: absolute;
    top: 50%;
    left: 5%;
    width: 90%;
    height: 250px;
    background-color: $white;
    padding: rem-calc(40);
    z-index: 10;

    .modal-content {
      padding: 40px 0 40px 0;
    }

    .close-modal {
      cursor: pointer;
      float: right;
      width: 15px;
      height: 15px;
    }
  }

  @include breakpoint(large up) {
    .info-modal, #modal-backdrop {
      display: none;
    }
  }
  
  .settings-btn {
    padding: rem-calc(5);
  }

  .info-guide, .account-info {
    width: 100px;

    @include breakpoint(small down) {
      width: 80px;
    }
  }

  .info-guide {
    display: none;
  }

  .info-guide-big {
    width: 100%;

    a {
      text-decoration: underline;
      font-weight: bold;
      margin-left: 10px;
      margin-right: 5px;
    }
  }

  @include breakpoint(medium down) {
    .info-guide {
      display:flex;
    }

    .info-guide-big {
      display: none;
    }
  }

  .icon-info {
    height: auto;
    width: 25px;
    vertical-align: top;
    margin-top: -5px;
    margin-right: 5px;
    color: $blue;
  }

  .icon-account {
    height: auto;
    width: 25px;
    vertical-align: top;
    margin-top: -5px;
    margin-right: 5px;
  }

  .nav-content {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    position: absolute;
    right: 20px;
    top: 96px;
    background-color: $white;
    filter: drop-shadow(5px 5px 5px rgb(169,169,169));

    @include breakpoint(small down) {
      top: 89px;
    }

    .main-nav-list {
      list-style: none;
      margin: 0;
      padding: 0 10px;

      li {
        padding: 10px 0;
      }

      button, a {
        font-weight: 400;
        margin: 0;
        padding: 0;
        color: $grey;
      }

      .clear {
        margin-top: 5px;
      }

      .nav-icon {
        width: 25px;
        height: auto;
        margin-right: 15px;
      }
    }

    a {
      text-decoration: none !important;
    }

    .change-password, .logout {
      button {
        @include flex();
        @include flex-align(null, 'middle');
      }
    }
  }

  .triangle {
    position: absolute;
    top: 45px;

    @include breakpoint(small down) {
      top: 37px;
    }
  }
}