@import '~foundation-sites/scss/util/util';

.reset-data-modal {

  .modal__content {
    min-height: rem-calc(346);
    height: auto;

    .resetDataWarningImage {
      margin: rem-calc(-4) rem-calc(16) 0 0;
    }
    .button {
      &.button-cancel {
        padding-left: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
